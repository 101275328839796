<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="tbmData.list"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="핵심행동을 추가하세요."
            :hideBottom="true"
            :editable="editable&&!disabled"
            rowKey="riskId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn v-if="editable && !disabled" :showLoading="false" label="SOP에서 선택" icon="add" @btnClicked="addSopRisk" />
                <c-btn v-if="editable && !disabled" :showLoading="false" label="위험등록부(JSA)에서 선택" icon="add" @btnClicked="addJsaRisk" />
                <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADDDIRECTLY" icon="add" @btnClicked="addRisk" />
                <c-btn v-if="editable && !disabled && tbmData.list.length > 0" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="removeRisk" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
export default {
  name: 'tbm-risk-hazard',
  props: {
    tbmData: {
      type: Object,
      default(){
        return {
          list: '',
          tbmId: '',
        }
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getUrl: '',
      grid: {
        columns: [
          {
            name: 'col0',
            field: 'col0',
            label: '작업단계',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col00',
            field: 'col00',
            label: '위험요인',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col01',
            field: 'col01',
            label: '안전대책',
            type: 'text',
            align: 'center',
            sortable: false,
          },
        ],
        height: '570px',
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    addSopRisk() {
      this.popupOptions.title = 'SOP 검색'; // SOP 검색
      this.popupOptions.param = {
        type: 'single',
        searchType: '2', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.grid.data.push({

        })
      }
    },
    addJsaRisk() {

    },
    closeJsaPopup() {

    },
    addRisk() {
      this.tbmData.list.splice(0, 0, {
        sopCtoId: this.tabParam.sopCtoId,
        sopCtoActivityId: uid(),
        mainActivity: '',
        remarks: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeRisk() {
      let selectData = this.$refs['riskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tbmData.deleteList) {
            this.tbmData.deleteList = []
          }
          if (this.$_.findIndex(this.tbmData.deleteList, { sopCtoActivityId: item.sopCtoActivityId }) === -1
            && item.editFlag !== 'C') {
              this.tbmData.deleteList.push(item)
          }
          this.tbmData.activityModels = this.$_.reject(this.tbmData.activityModels, item)
        })
      }
    },
  }
};
</script>